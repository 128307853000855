import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: "cn",
    eq: "pc",
  },
  mutations: {
    changeLang(state) {
      if (state.lang === "cn") {
        state.lang = "en";
      } else {
        state.lang = "cn";
      }
    },
    checkEq(state, payload) {
      state.eq = payload;
    },
  },
  actions: {},
  modules: {},
});

<template>
  <div id="header">
    <el-row class="nav-topbar">
      <el-col :span="8">
        <div class="logo"><img src="http://cdn.valuetech.com.cn/images/logo.png"></div>
      </el-col>
      <el-col class="container" ref="nav" @click.native="_addClass" :span="8">
        <router-link to="/"><span id="homeNav" class="navActive text_ov_el index">{{$t('topBar.n1')}}</span></router-link>
        <router-link to="/servicesAvailable"><span class="text_ov_el servicesAvailable">{{$t('topBar.n2')}}</span></router-link>
        <router-link to="/independentProduct"><span class="text_ov_el independentProduct">{{$t('topBar.n3')}}</span></router-link>
        <router-link to="/caseShows"><span class="text_ov_el caseShows">{{$t('topBar.n4')}}</span></router-link>
        <!-- <router-link to="/newsCenter"><span class="text_ov_el newsCenter">{{$t('topBar.n5')}}</span></router-link> -->
        <router-link to="/about"><span class="text_ov_el about">{{$t('topBar.n6')}}</span></router-link>
      </el-col>
      <el-col :span="8">
        <div class="checkBtn" @click="changeLanguage()"><span :class="{'btnActive':btnIsActive}">CN</span>&nbsp;|&nbsp;<span :class="{'btnActive':!btnIsActive}">EN</span></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Bus from "@/utils/bus.js";

import { addClass } from "@/utils";

export default {
  name: "NavHeader",
  data() {
    return {
      btnIsActive: true,
      bgUpd: true,
    };
  },
  methods: {
    changeLanguage() {
      this.btnIsActive = !this.btnIsActive;
      this.$i18n.locale == "cn"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "cn");
      localStorage.setItem("languageSet", this.$i18n.locale);
      this.$store.commit("changeLang");
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    },
    _addClass(e) {
      this.items = document
        .getElementsByClassName("container")[0]
        .getElementsByTagName("span");
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        item.classList.remove("navActive");
      }
      if (e.target.nodeName === "A") {
        e.target.children[0].classList.add("navActive");
      } else {
        e.target.classList.add("navActive");
      }
      if (document.getElementById("homeNav").classList.contains("navActive")) {
        document.getElementById("header").style.background = "rgba(0,0,0,0)";
      } else {
        document.getElementById("header").style.background = "rgba(0,0,0,0.7)";
      }
    },
    handleScroll() {
      if (this.$route.path !== "/index") {
        return;
      }
      if (!document.getElementById("homeNav").classList.contains("navActive")) {
        return;
      }
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop > 200 && this.bgUpd) {
        //大于200的时候要做的操作
        document.getElementById("header").style.background = "rgba(0,0,0,0.7)";
      }
      if (scrollTop < 200 && this.bgUpd) {
        //大于200的时候要做的操作
        document.getElementById("header").style.background = "rgba(0,0,0,0)";
      }
    },
  },
  created() {
    if (JSON.parse(sessionStorage.getItem("store")).lang == "cn") {
      this.btnIsActive = true;
    } else {
      this.btnIsActive = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.$route.path == "/news") {
      document.getElementById("homeNav").classList.remove("navActive");
    }
    if (this.$route.path == "/index") {
      if (document.getElementById("homeNav").classList.contains("navActive")) {
        document.getElementById("header").style.background = "rgba(0,0,0,0)";
      }
    }
    Bus.$on("changeNav", (routerName) => {
      this.items = document
        .getElementsByClassName("container")[0]
        .getElementsByTagName("span");
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        item.classList.remove("navActive");
      }
      document.getElementsByClassName(routerName)[0].classList.add("navActive");
    });
    if (sessionStorage.getItem("navState")) {
      Bus.$emit("changeNav", sessionStorage.getItem("navState"));
    }
  },
};
</script>
<style lang="scss">
@import "assets/scss/base.scss";
@import "assets/scss/mixin.scss";
@import "assets/scss/config.scss";
@import "assets/scss/global.scss";

#header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  z-index: 99;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ccc;
  text-align: center;
  z-index: 111;
  .nav-topbar {
    .logo {
      height: 70px;
      img {
        width: 100px;
        height: 45px;
      }
    }
    .container {
      @include flex();
      a {
        // width: 55px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        display: inline-block;
        text-decoration: none;
      }
      a:hover {
        font-weight: bold;
        span {
          padding-bottom: 15px;
          border-bottom: 2px solid red;
        }
      }
      .navActive {
        padding-bottom: 15px;
        border-bottom: 2px solid red;
      }
    }
  }
  .checkBtn {
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    .btnActive {
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
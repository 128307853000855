const info = {
  ZH: [
    {
      title: "数字化基础建设",
      cardImgUrl: require("assets/images/图层 588.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "实现车企降本增效，并通过与用户直接接触和运营，变革自身盈利模式，对于用户来说，能够大幅优化购车、用车体验。",
      },
      other: [
        {
          title: "OneID",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/oneID.png",
          itemMsg:
            "通过对企业各触点入口统合、账号统合、信息统合，打破数据孤岛，实现数据通融。",
        },
        {
          title: "粉丝社区",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%B2%89%E4%B8%9D%E7%A4%BE%E5%8C%BA.png",
          itemMsg:
            "吸引和汇聚品牌粉丝群体的社区平台，利用粉丝传导，实现粉丝效应的价值转化。",
        },
        {
          title: "电商平台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%94%B5%E5%95%86%E5%B9%B3%E5%8F%B0.png",
          itemMsg:
            "吸引和汇聚品牌粉丝群体的社区平台，利用粉丝传导，实现粉丝效应的价值转化。",
        },
      ],
    },
    {
      title: "数字化展厅",
      cardImgUrl: require("assets/images/图层 591.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85.png",
        itemMsg:
          "通过智能化软硬件的引入建设，对展厅进行统一赋能，为客户提供智能互动、智能引导、智能查询等服务，有效提升了客户的服务体验。",
      },
      other: [
        {
          title: "互动大屏",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E4%BA%92%E5%8A%A8%E5%A4%A7%E5%B1%8F.png",
          itemMsg:
            "利用最先进的3D/VR/AI技术，软硬件结合，为客户提供更加激动人心的服务体验，彰显品牌的豪华感/科技感。",
        },
        {
          title: "电子价格牌",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E7%94%B5%E5%AD%90%E4%BB%B7%E6%A0%BC%E7%89%8C.png",
          itemMsg:
            "支持大规模、零改造落地，可作为线下场景中客户交互及数据收集、反馈的重要触点。",
        },
        {
          title: "智慧听鉴",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%99%BA%E6%85%A7%E5%90%AC%E9%89%B4.png",
          itemMsg:
            "离线录音，角色识别、在线解析按角色对话翻译，智能分析、质量评定、客户洞察，为销售赋能。",
        },
      ],
    },
    {
      title: "会员体系建设",
      cardImgUrl: require("assets/images/组 3.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB.png",
        itemMsg:
          "作为客户旅程中的关键元素之一，在品牌与客户/潜在客户互动的生态系统中扮演着非常重要的角色！是管理品牌会员机制及其日常运营的核心门户。",
      },
      other: [
        {
          title: "会员等级权益体系",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7%E6%9D%83%E7%9B%8A%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "等级&权益体系为核心板块搭建会员体系的文化宣导，提升用户忠诚度。",
        },
        {
          title: "会员积分体系",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
          itemMsg: "拉新促活，创造基于会员体系的营销价值，提高用户活跃度。",
        },
        {
          title: "会员营销体系",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E8%90%A5%E9%94%80%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "卡券中心、活动引擎搭建会员业务统一能力输出的激励平台，提升用户转化。",
        },
      ],
    },
    {
      title: "车联网",
      cardImgUrl: require("assets/images/资源 1.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%81%94%E7%BD%91.png",
        itemMsg:
          "提供车与车、车与人、人与人移动互联整体解决方案，包括数字钥匙、车载终端应用等解决方案。",
      },
      other: [
        {
          title: "数字钥匙",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%95%B0%E5%AD%97%E9%92%A5%E5%8C%99.png",
          itemMsg:
            "结合车辆、移动终端、云服务三端，三位一体的车辆钥匙保护体系，提升用车体验。",
        },
        {
          title: "车载终端应用",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%BD%BD%E7%BB%88%E7%AB%AF%E5%BA%94%E7%94%A8.png",
          itemMsg: "车载微信、QQ、音乐、FM、语音助手等智能车载应用的基础服务。",
        },
        {
          title: "",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%BD%BD%E7%BB%88%E7%AB%AF%E5%BA%94%E7%94%A8.png",
          itemMsg: "-",
        },
      ],
    },
    {
      title: "数字化营销",
      cardImgUrl: require("assets/images/矢量智能对象(1).png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80.png",
        itemMsg:
          "提供从流量引入-流量池建设-活动营销-线索生成-线索库建立到线索持续挖掘转化全流程数字营销解决方案。",
      },
      other: [
        {
          title: "SCRM",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/scrm.png",
          itemMsg:
            "覆盖客户全生命周期引流转化, 线上联动线下, 聚合客户的社交连接。",
        },
        {
          title: "用户成长系统",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E7%94%A8%E6%88%B7%E6%88%90%E9%95%BF%E7%B3%BB%E7%BB%9F.png",
          itemMsg:
            "简历用户详细的分层数据模型，进行用户精细化管理，促进用户成长。",
        },
        {
          title: "数字分析平台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%95%B0%E5%AD%97%E5%88%86%E6%9E%90%E5%B9%B3%E5%8F%B0.png",
          itemMsg:
            "提供全场景数据埋点、数据收集汇总、数据提取、数据整合、数据分析报表到业务洞察报告输出的全业务支持。",
        },
      ],
    },
    {
      title: "中台建设",
      cardImgUrl: require("assets/images/图层 608 拷贝.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "提升企业数据采集、计算、存储、加工能力，提升企业业务集成、体验、运营、对接的能力，提升企业技术快速支持的能力。",
      },
      other: [
        {
          title: "数据中台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "帮助企业从数据汇聚、数据治理、数据提炼、数据建模、数据资产可视化服务赋能的持续数据资产化的建设支持。",
        },
        {
          title: "业务中台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E4%B8%9A%E5%8A%A1%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "可共享可复用业务的抽取沉淀，解决企业前端业务的重复建设，提升业务能力。",
        },
        {
          title: "技术中台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%8A%80%E6%9C%AF%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "可共享可复用底层技术的集成，快速为前端提供底层逻辑技术、能力的支持。",
        },
      ],
    },
    {
      title: "AI客服中心",
      cardImgUrl: require("assets/images/矢量智能对象.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83.png",
        itemMsg:
          "采用机器人+人工双重服务模式，智能机器人解决常见问题，人工坐席解决个性化问题，降本提效同时提升客户体验。",
      },
      other: [
        {
          title: "语音识别服务",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E8%AF%AD%E9%9F%B3%E8%AF%86%E5%88%AB%E6%9C%8D%E5%8A%A1.png",
          itemMsg: "语音收集、语义识别、关键词提取等智能语音服务。",
        },
        {
          title: "AI自学习",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E8%87%AA%E4%B8%BB%E5%AD%A6%E4%B9%A0.png",
          itemMsg:
            "通过客服接待，自动进行关键词的识别沉淀并不断学习提升服务能力。",
        },
        {
          title: "知识库",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E7%9F%A5%E8%AF%86%E5%BA%93.png",
          itemMsg:
            "通过客服接待，提取价值信息不断完善知识库并进行素材标签化，帮助销售更精准提供服务。",
        },
      ],
    },
  ],
  EN: [
    {
      title: "Digital infrastructure",
      cardImgUrl: require("assets/images/图层 588.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "Through direct contact with users and operation, it can change its own profit model, and greatly optimize the experience of car purchase and use for users.",
      },
      other: [
        {
          title: "OneID",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/oneID.png",
          itemMsg:
            "Through the integration of each contact entry, account and information of the enterprise, the data island is broken and the data accommodation is realized.",
        },
        {
          title: "Fan community",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%B2%89%E4%B8%9D%E7%A4%BE%E5%8C%BA.png",
          itemMsg:
            "A community platform to attract and gather brand fans, and realize the value transformation of fans effect by using fans transmission.",
        },
        {
          title: "E-commerce platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%94%B5%E5%95%86%E5%B9%B3%E5%8F%B0.png",
          itemMsg:
            "Now almost all vehicle purchases start from online search, because the majority of customers have become accustomed to the offline service model of online ticket booking.",
        },
      ],
    },
    {
      title: "Digital Exhibition Hall",
      cardImgUrl: require("assets/images/图层 591.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85.png",
        itemMsg:
          "Through the introduction and construction of intelligent hardware and software, the exhibition hall is unified and empowered to provide customers with intelligent interaction, intelligent guidance, intelligent inquiry and other services, which effectively improves customer service experience.",
      },
      other: [
        {
          title: "Interactive large screen",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E4%BA%92%E5%8A%A8%E5%A4%A7%E5%B1%8F.png",
          itemMsg:
            "Utilize the most advanced 3D/VR/AI technology and the combination of software and hardware to provide customers with a more exciting service experience, highlighting the brand’s sense of luxury/technology.",
        },
        {
          title: "Electronic price tag",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E7%94%B5%E5%AD%90%E4%BB%B7%E6%A0%BC%E7%89%8C.png",
          itemMsg:
            "Support large-scale, zero-renovation landing, which can be used as an important touch point for customer interaction and data collection and feedback in offline scenarios.",
        },
        {
          title: "Wisdom Listening",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%99%BA%E6%85%A7%E5%90%AC%E9%89%B4.png",
          itemMsg:
            "Off-line recording, role identification, online analysis according to the role of dialogue translation, intelligent analysis, quality assessment, customer insight, to enable sales.",
        },
      ],
    },
    {
      title: "Member System",
      cardImgUrl: require("assets/images/组 3.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB.png",
        itemMsg:
          "As one of the key elements in the customer journey, it plays a very important role in the ecosystem where the brand interacts with customers/potential customers! It is the core portal to manage the brand membership mechanism and its daily operations.",
      },
      other: [
        {
          title: "Membership level rights system",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7%E6%9D%83%E7%9B%8A%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "The hierarchy and the rights and interests system build the cultural promotion of the membership system for the core section, and enhance the loyalty of users.",
        },
        {
          title: "Member Points System",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "Pull new and promote activities, create marketing value based on the membership system, and increase user activity.",
        },
        {
          title: "Member marketing system",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E8%90%A5%E9%94%80%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "The coupon center and the event engine build an incentive platform for the unified output of member services to enhance user conversion.",
        },
      ],
    },
    {
      title: "Internet of Vehicles",
      cardImgUrl: require("assets/images/资源 1.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%81%94%E7%BD%91.png",
        itemMsg:
          "Provide overall solutions for the mobile interconnection of cars and cars, cars and people, and people and people, including solutions such as digital keys and in-vehicle terminal applications.",
      },
      other: [
        {
          title: "Digital key",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%95%B0%E5%AD%97%E9%92%A5%E5%8C%99.png",
          itemMsg:
            "Combining the three terminals of vehicles, mobile terminals, and cloud services, the trinity vehicle key protection system improves the car experience.",
        },
        {
          title: "Vehicle terminal application",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%BD%BD%E7%BB%88%E7%AB%AF%E5%BA%94%E7%94%A8.png",
          itemMsg:
            "Car WeChat、QQ、music、FM、Basic services for smart car applications such as voice assistants.",
        },
        {
          title: "",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%BD%BD%E7%BB%88%E7%AB%AF%E5%BA%94%E7%94%A8.png",
          itemMsg:
            "Car WeChat、QQ、music、FM、Basic services for smart car applications such as voice assistants.",
        },
      ],
    },
    {
      title: "Digital marketing",
      cardImgUrl: require("assets/images/矢量智能对象(1).png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80.png",
        itemMsg:
          "Provide full-process digital marketing solutions from traffic introduction, traffic pool construction, event marketing, clue generation, clue database establishment to clue continuous mining and conversion.",
      },
      other: [
        {
          title: "SCRM",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/scrm.png",
          itemMsg:
            "Cover the customer's full life cycle drainage and conversion, online linkage and offline, and aggregate customer social connections.",
        },
        {
          title: "User growth system",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E7%94%A8%E6%88%B7%E6%88%90%E9%95%BF%E7%B3%BB%E7%BB%9F.png",
          itemMsg:
            "Detailed hierarchical data model of resume users, refined user management, and promote user growth.",
        },
        {
          title: "Digital analysis platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%95%B0%E5%AD%97%E5%88%86%E6%9E%90%E5%B9%B3%E5%8F%B0.png",
          itemMsg:
            "Provides full-service support for all-scene data burying points, data collection and summary, data extraction, data integration, data analysis reports and business insight report output.",
        },
      ],
    },
    {
      title: "Middle Platform Construction",
      cardImgUrl: require("assets/images/图层 608 拷贝.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "Enhance corporate data collection, calculation, storage, and processing capabilities, enhance corporate business integration, experience, operation, and docking capabilities, and enhance corporate technology rapid support capabilities.",
      },
      other: [
        {
          title: "Data middle platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "Help enterprises to support the construction of continuous data assetization from data aggregation, data governance, data extraction, data modeling, and data asset visualization services.",
        },
        {
          title: "Business middle platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E4%B8%9A%E5%8A%A1%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "The extraction and precipitation of shared and reusable services can solve the repeated construction of enterprise front-end services and improve business capabilities.",
        },
        {
          title: "Technology middle platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%8A%80%E6%9C%AF%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "The integration of the shareable and reusable underlying technology can quickly provide the front-end with the support of the underlying logic technology and capabilities.",
        },
      ],
    },
    {
      title: "AI Service",
      cardImgUrl: require("assets/images/矢量智能对象.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83.png",
        itemMsg:
          "The dual service model of robot + manual is adopted, intelligent robots solve common problems, and artificial seats solve individualized problems, reducing costs and improving efficiency while improving customer experience.",
      },
      other: [
        {
          title: "Speech recognition service",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E8%AF%AD%E9%9F%B3%E8%AF%86%E5%88%AB%E6%9C%8D%E5%8A%A1.png",
          itemMsg:
            "Voice collection, semantic recognition, keyword extraction and other intelligent voice services.",
        },
        {
          title: "AI self-learning",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E8%87%AA%E4%B8%BB%E5%AD%A6%E4%B9%A0.png",
          itemMsg:
            "Through customer service reception, automatic keyword recognition and precipitation, and continuous learning to improve service capabilities.",
        },
        {
          title: "knowledge base",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E7%9F%A5%E8%AF%86%E5%BA%93.png",
          itemMsg:
            "Through customer service reception, extract value information and continuously improve the knowledge base and label materials to help sales provide more accurate services.",
        },
      ],
    },
  ],
};
export default info;

const info = {
    ZH: [
        {
          title: "道道通打赢凯立德导航地图知识产权维权难",
          imgUrl: require("assets/images/苏州台案例照片-1.jpg"),
          msg:
            "致力于为客户提供综合数字展示相关服务，全力推动零售行业数字化变革及数字营销转型",
          time: "2021-07-02",
        },
        {
          title: "道道通打赢凯立德导航地图知识产权维权难",
          imgUrl: require("assets/images/快手项目图片2.jpg"),
          msg:
            "致力于为客户提供综合数字展示相关服务，全力推动零售行业数字化变革及数字营销转型",
          time: "2021-07-02",
        },
        {
          title: "道道通打赢凯立德导航地图知识产权维权难",
          imgUrl: require("assets/images/3.png"),
          msg:
            "致力于为客户提供综合数字展示相关服务，全力推动零售行业数字化变革及数字营销转型",
          time: "2021-07-02",
        },
      ],
    EN: [
        {
          title: "a",
          imgUrl: require("assets/images/苏州台案例照片-1.jpg"),
          msg:
            "致力于为客户提供综合数字展示相关服务，全力推动零售行业数字化变革及数字营销转型",
          time: "2021-07-02",
        },
        {
          title: "b",
          imgUrl: require("assets/images/快手项目图片2.jpg"),
          msg:
            "致力于为客户提供综合数字展示相关服务，全力推动零售行业数字化变革及数字营销转型",
          time: "2021-07-02",
        },
        {
          title: "c",
          imgUrl: require("assets/images/3.png"),
          msg:
            "致力于为客户提供综合数字展示相关服务，全力推动零售行业数字化变革及数字营销转型",
          time: "2021-07-02",
        },
      ]
}
export default info
import Vue from "vue";
import VueRouter from "vue-router";
import PCHome from "views/PCHome.vue";
import MHome from "views/MHome.vue";
import News from "views/News.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: PCHome,
    redirect: "index",
    children: [
      {
        path: "index",
        name: "Index",
        meta: {
          title: "湾流(北京)智能科技有限公司",
        },
        component: () => import("views/pc/index.vue"),
      },
      {
        path: "servicesAvailable",
        name: "ServicesAvailable",
        meta: {
          title: "服务项目",
        },
        component: () => import("views/pc/ServicesAvailable.vue"),
      },
      {
        path: "independentProduct",
        name: "IndependentProduct",
        meta: {
          title: "自主产品",
        },
        component: () => import("views/pc/IndependentProduct.vue"),
      },
      {
        path: "caseShows",
        name: "CaseShows",
        meta: {
          title: "案例展示",
        },
        component: () => import("views/pc/CaseShows.vue"),
      },
      {
        path: "newsCenter",
        name: "NewsCenter",
        meta: {
          title: "新闻中心",
        },
        component: () => import("views/pc/NewsCenter.vue"),
      },
      {
        path: "about",
        name: "About",
        meta: {
          title: "关于我们",
        },
        component: () => import("views/pc/About.vue"),
      },
    ],
  },
  {
    path: "/mobile",
    name: "MobileHome",
    component: MHome,
    redirect: "/mobile/mindex",
    children: [
      {
        path: "mindex",
        name: "MIndex",
        meta: {
          title: "湾流(北京)智能科技有限公司",
        },
        component: () => import("views/mobile/index.vue"),
      },
      {
        path: "servicesAvailable",
        name: "MServicesAvailable",
        meta: {
          title: "服务项目",
        },
        component: () => import("views/pc/ServicesAvailable.vue"),
      },
      {
        path: "independentProduct",
        name: "IndependentProduct",
        meta: {
          title: "自主产品",
        },
        component: () => import("views/pc/IndependentProduct.vue"),
      },
      {
        path: "caseShows",
        name: "MCaseShows",
        meta: {
          title: "案例展示",
        },
        component: () => import("views/pc/CaseShows.vue"),
      },
      {
        path: "newsCenter",
        name: "NewsCenter",
        meta: {
          title: "新闻中心",
        },
        component: () => import("views/pc/NewsCenter.vue"),
      },
      {
        path: "about",
        name: "About",
        meta: {
          title: "关于我们",
        },
        component: () => import("views/pc/About.vue"),
      },
    ],
  },
  {
    path: "/news",
    name: "News",
    meta: {
      title: "新闻",
    },
    component: () => import("views/News.vue"),
  },
  {
    path: "/mnews",
    name: "MNews",
    meta: {
      title: "新闻",
    },
    component: () => import("views/News.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;

import TopBar from "./data/topBar";
import newsMsg from "./data/newsMsg";
import serveProMsg from "./data/serveProMsg";
import serverMsg from "./data/serverMsg";
import casesMsg from "./data/casesMsg";
import casesShowMsg from "./data/casesShowMsg";
import productMsg from "./data/productMsg";

const zh = {
  language: {
    name: "中文",
  },
  topBar: TopBar.ZH,
  newsMsg: newsMsg.ZH,
  serveProMsg: serveProMsg.ZH,
  serverMsg: serverMsg.ZH,
  casesMsg: casesMsg.ZH,
  casesShowMsg: casesShowMsg.ZH,
  productMsg: productMsg.ZH,
  xzcy: "下载彩页",
  ljxq: "了解详情",
  copyRight:
    "版权所有 湾流(北京)智能科技有限公司 备案号 :",
  aboutBtnName: ["发展历程", "企业介绍", "企业文化", "企业资质"],
  aboutMsg: {
    companyProfile:
      "湾流(北京)智能科技有限公司位于北京中关村东升科技园内，是一家致力于为客户提供综合数字展示相关服务，全力推动行业数字化变革及数字营销转型的综合解决方案提供商，获得国家办法的高新技术企业认证资质。核心解决方案覆盖智能化音视频系统集成，数字孪生环境构架，综合大数据交互信传、AR/VR交互视频墙、机器视觉/语音、商业服务机器人等领域，拥有二十余项涵盖自主知识产权的软硬件产品。公司长期与众多国际企业保持紧密合作，是松下、东芝、佳能、三星、飞利浦、快思聪、ATLAS等厂商的授权合作伙伴。",
    companyCulture:
      "湾流(北京)智能科技有限公司位于北京中关村东升科技园内，是一家致力于为客户提供综合数字展示相关服务，全力推动行业数字化变革及数字营销转型的综合解决方案提供商，获得国家办法的高新技术企业认证资质。核心解决方案覆盖智能化音视频系统集成，数字孪生环境构架，综合大数据交互信传、AR/VR交互视频墙、机器视觉/语音、商业服务机器人等领域，拥有二十余项涵盖自主知识产权的软硬件产品。公司长期与众多国际企业保持紧密合作，是松下、东芝、佳能、三星、飞利浦、快思聪、ATLAS等厂商的授权合作伙伴。",
    title: "通过以下方式取得联系",
    phone: "联系电话：010-58458282 ",
    email: "邮箱地址：mail@valuetech.com.cn",
    address:
      "联系地址：北京市海淀区安宁庄东路甲16号院兴安商务办公区5号楼166室 ",
    mapModalTitle: "湾流公司",
    mapModalContainer:
      "北京市海淀区安宁庄东路甲16号院兴安商务办公区5号楼166室 ",
  },
};

export default zh;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { Steps } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import {
  DropdownMenu,
  DropdownItem,
  Icon,
  Swipe,
  SwipeItem,
  Lazyload,
  Col,
  Row,
  Tab,
  Tabs,
} from "vant";
import "vant/lib/index.css";
import VueI18n from "vue-i18n";
import VueAMap from "vue-amap";
import moment from "moment";
import VueLazyload from "vue-lazyload";
// import "lib-flexible";

import ZH from "@/components/language/zh.js";
import EN from "@/components/language/en.js";
import "@/assets/css/normalize.css";

import Bus from "@/utils/bus.js";

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

Vue.use(Element);
Vue.use(Steps);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tab);
Vue.use(Tabs);

Vue.use(VueI18n);
Vue.use(VueAMap);
Vue.use(VueLazyload);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: "dist/error.png",
  // loading: "dist/loading.gif",
  attempt: 1,
});

router.beforeEach((to, from, next) => {
  Bus.$emit("changeNav", to.path.replace("/", ""));
  Bus.$emit("changeMNav", to.path.replace("/mobile/", ""));
  if (to.meta.title) {
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
    document.title = to.meta.title;
  }
  next();
});
router.afterEach((to, from) => {
  sessionStorage.setItem("navState", to.path.replace("/", ""));
});

const i18n = new VueI18n({
  locale: localStorage.getItem("languageSet") || "cn",
  messages: {
    cn: ZH,
    en: EN,
  },
});

VueAMap.initAMapApiLoader({
  key: "bab965ff8e7e8d6beccc71baa2329e07",
  plugin: [
    "AMap.Geolocation",
    "AMap.AutoComplete",
    "AMap.PlaceSearch",
    "AMap.Driving",
    "AMap.Transfer",
    "AMap.Walking",
    "AMap.AdvancedInfoWindow",
  ],
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

<template>
    <el-row class="navfooter" type="flex" justify="center">
      <el-col :span="10" class="capyRight">
        <p class="text_ov_el">{{$t('copyRight')}} <a style="color: #ffffff;" href='http://beian.miit.gov.cn'> 京ICP备15004518号-1</a></p>
      </el-col>
      <el-col :span="6">
      </el-col>
      <el-col :span="4">
        <img class="QRcode" src="http://cdn.valuetech.com.cn/images/%E9%A1%B5%E8%84%9A-%E4%BA%8C%E7%BB%B4%E7%A0%81.png" alt="">
      </el-col>
    </el-row>
</template>

<script>
export default {
  name: "NavFooter",
};
</script>
<style lang="scss">
.navfooter {
  margin: 10px 0 -3px 0;
  padding: 0 0 10px 0;
  height: 117px;
  line-height: 117px;
  background: #0c6ac1;
  bottom: 0px;
  .capyRight {
    font-size: 14px;
    height: 117px;
    line-height: 117px;
    font-weight: 400;
    color: #ffffff;
    p {
      margin-bottom: 0;
    }
  }
  .QRcode {
    height: 85px;
  }
}
@media screen and(max-width:1200px) {
  .navfooter {
    left: 0;
    bottom: 0;
    .capyRight {
      p {
        position: relative;
        top: 50%;
        margin-top: -20px;
        left: -25px;
        width: 223px;
        height: 40px;
        line-height: 20px;
        font-size: 12px;
        transform: scale(0.8);
        white-space: pre-wrap;
      }
    }
  }
}
</style>


const info = {
  ZH: [
    {
      title: "电子价格牌",
      imgUrl:
        "http://cdn.valuetech.com.cn/images/%E8%87%AA%E4%B8%BB%E4%BA%A7%E5%93%81/%E7%94%B5%E5%AD%90%E4%BB%B7%E6%A0%BC%E7%89%8C.png",
      itemMsg:
        "随着时代发展，科技不断进步，新一代消费者对于线下门店的体验有着更高的要求，电子价格牌具备iOS/Android双系统支持、部署便捷/维护简单、支持大规模零售改造落地、资源与下载技术保障信息及时性和客户体验性、结合智能充电管家解决续航问题等特性，可作为线下场景中客户交互及数据收集、反馈的重要触点。",
    },
    {
      title: "智能充电管家",
      imgUrl:
        "http://cdn.valuetech.com.cn/images/%E8%87%AA%E4%B8%BB%E4%BA%A7%E5%93%81/%E8%87%AA%E4%B8%BB%E4%BA%A7%E5%93%81-1.png",
      itemMsg:
        "专用于智能电源适配器产品充电、管理和存储的智能一体化设备，具有定时充电、状态监管、智能温控、设备保护、人身安全保护等功能，产品实现了自动托管式管理，功能多样化、使用人性化。",
    },
  ],
  EN: [
    {
      title: "Electronic price tag",
      imgUrl:
        "http://cdn.valuetech.com.cn/images/%E8%87%AA%E4%B8%BB%E4%BA%A7%E5%93%81/%E7%94%B5%E5%AD%90%E4%BB%B7%E6%A0%BC%E7%89%8C.png",
      itemMsg:
        "With the development of the times and the continuous advancement of technology, the new generation of consumers have higher requirements for the experience of offline stores. The electronic price tag has iOS/Android dual system support, convenient deployment/easy maintenance, support for large-scale retail transformation, and resources With download technology to ensure the timeliness of information and customer experience, combined with intelligent charging butler to solve battery life issues and other features, it can be used as an important touch point for customer interaction, data collection and feedback in offline scenarios.",
    },
    {
      title: "Smart charging butler",
      imgUrl:
        "http://cdn.valuetech.com.cn/images/%E8%87%AA%E4%B8%BB%E4%BA%A7%E5%93%81/%E8%87%AA%E4%B8%BB%E4%BA%A7%E5%93%81-1.png",
      itemMsg:
        "Intelligent integrated equipment dedicated to the charging, management and storage of smart power adapter products. It has functions such as timing charging, status supervision, intelligent temperature control, equipment protection, and personal safety protection. The product realizes automatic managed management, diversified functions and uses Humanize.",
    },
  ],
};
export default info;

const info = {
  ZH: [
    {
      content1: {
        title: "某日系豪华汽车品牌OneID",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8COneID.png",
        msg:
          "品牌打造以移动出行服务为目的的全新商业模式，OneID帮助品牌方打破数据孤岛，链接全触点，统合全域用户，成为品牌建设一元化信息平台的基石。",
      },
      content2: {
        title: "某日系二手车平台",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E4%BA%8C%E6%89%8B%E8%BD%A6%E5%B9%B3%E5%8F%B0.png",
        msg:
          "随着新车市场的饱和，中国车市场由增量变为存量时代，品牌为了提升客户服务，打造了二手车平台，帮助车主更好的进行车辆的换购。",
      },
      content3: {
        title: "某韩系社交建设",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E9%9F%A9%E7%B3%BB%E7%A4%BE%E4%BA%A4%E5%BB%BA%E8%AE%BE.png",
        msg:
          "围绕用户全生命周期搭建的用车服务/车生活/ 社交于一体的智能服务平台，促进用户向粉丝转化，实现口碑和销售裂变，提升品牌价值。",
      },
      content4: {
        title: "电子展架续航充电柜",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%94%B5%E5%AD%90%E5%B1%95%E6%9E%B6%E7%BB%AD%E8%88%AA%E5%85%85%E7%94%B5%E6%9F%9C.png",
        msg:
          "为保证经销商展厅各场景电子展架/电子价格牌等设备正常运营，湾流帮助车企打造了充电柜自研产品，解决经销商展厅设备续航问题。",
      },
    },
    {
      content1: {
        title: "奔驰数字展厅软硬件系统建设",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E5%A5%94%E9%A9%B0%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E8%BD%AF%E7%A1%AC%E4%BB%B6%E7%B3%BB%E7%BB%9F%E5%BB%BA%E8%AE%BE.png",
        msg:
          "随着时代的发展，新一代消费者对于线下门店的体验有着更高的要求，奔驰为了提升到店客户的体验进行了整店软硬件数字化、智能化升级。",
      },
      content2: {
        title: "某日系豪华汽车品牌数字展厅建设",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E5%BB%BA%E8%AE%BE.png",
        msg:
          "随着科技的不断进步，为品牌进行了数字展厅体验全面升级，店内车型不齐的情况下给客户接近真实的看车体验。",
      },
      content3: {
        title: "某日系豪华汽车品牌数字展厅建设",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E5%8C%97%E6%B1%BD%E6%96%B0%E8%83%BD%E6%BA%90%E9%87%91%E6%B8%AF%E5%B1%95%E5%8E%85.png",
        msg:
          "随着科技的不断进步，为品牌进行了数字展厅体验全面升级，店内车型不齐的情况下给客户接近真实的看车体验。",
      },
      content4: {
        title: "某日系豪华汽车品牌数字展厅建设",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/VTI%E4%BA%BA%E8%84%B8%E8%AF%86%E5%88%AB%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        msg:
          "随着科技的不断进步，为品牌进行了数字展厅体验全面升级，店内车型不齐的情况下给客户接近真实的看车体验。",
      },
    },
    {
      content1: {
        title: "某日系豪华汽车品牌会员等级权益体系",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7%E6%9D%83%E7%9B%8A%E4%BD%93%E7%B3%BB.png",
        msg:
          "为了增强品牌会员的用车服务，在OneID的支持下，为品牌全触点建设了统一的等级和对应的权益服务。",
      },
      content2: {
        title: "某日系豪华汽车品牌积分体系",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
        msg:
          "为了增强触点用户的活跃和价值，进行了积分体系的建设，包含“积分获取事件定义、积分管理、积分商城”等体系的建设。",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
        msg:
          "为了增强触点用户的活跃和价值，进行了积分体系的建设，包含“积分获取事件定义、积分管理、积分商城”等体系的建设。",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
        msg:
          "为了增强触点用户的活跃和价值，进行了积分体系的建设，包含“积分获取事件定义、积分管理、积分商城”等体系的建设。",
      },
    },
    {
      content1: {
        title: "某日系豪华汽车品牌数字钥匙系统",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E9%92%A5%E5%8C%99%E7%B3%BB%E7%BB%9F.png",
        msg:
          "为了更好的提升客户用车体验，以及车共享服务，帮助品牌进行了数字钥匙系统用户端的建设，包含“钥匙流转、亲友共享、钥匙回收”等。",
      },
      content2: {
        title: "某日系豪华汽车品牌待客入厂服务",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E5%BE%85%E5%AE%A2%E5%85%A5%E5%8E%82%E6%9C%8D%E5%8A%A1.png",
        msg:
          "基于数字钥匙的增值服务，车主通过数字钥匙授权的方式，代驾可无钥匙操作车辆进行待客返厂维保/充电等服务。",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E5%BE%85%E5%AE%A2%E5%85%A5%E5%8E%82%E6%9C%8D%E5%8A%A1.png",
        msg:
          "基于数字钥匙的增值服务，车主通过数字钥匙授权的方式，代驾可无钥匙操作车辆进行待客返厂维保/充电等服务。",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E5%BE%85%E5%AE%A2%E5%85%A5%E5%8E%82%E6%9C%8D%E5%8A%A1.png",
        msg:
          "基于数字钥匙的增值服务，车主通过数字钥匙授权的方式，代驾可无钥匙操作车辆进行待客返厂维保/充电等服务。",
      },
    },
    {
      content1: {
        title: "Volvo SCRM",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/Volvo%20SCRM.png",
        msg:
          "以数据为依托，通过服务支持、营销支持、数据支持，覆盖客户户全生命周期引流转化，线上联动线下，聚合客户实现品牌共创。",
      },
      content2: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%88%86%E6%9E%90.png",
        msg: "-",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%B9%BE%E6%B5%81%E6%99%BA%E8%83%BDCRM%E6%A1%88%E4%BE%8B%E9%9B%86.png",
        msg: "-",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E5%8C%97%E6%B1%BD%E6%8E%A8%E8%8D%90%E5%B1%95%E7%A4%BA%E6%96%B9%E6%A1%88.png",
        msg: "-",
      },
    },
    {
      content1: {
        title: "某日系豪华汽车品牌数据中台方案",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%8F%B0%E6%96%B9%E6%A1%88.png",
        msg:
          "各触点数据割裂，导致数据价值无法最大化、商业化，通过OneID的支持，结合数据治理、数据模型化、业务需求支持的建设，让数据价值最大化。",
      },
      content2: {
        title: "某德系豪华车品牌业务中台解决方案",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E5%BE%B7%E7%B3%BB%E8%B1%AA%E5%8D%8E%E8%BD%A6%E5%93%81%E7%89%8C%E4%B8%9A%E5%8A%A1%E4%B8%AD%E5%8F%B0%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        msg:
          "随着品牌业务的发展，各业务单独建设维护的成本日益升高，效率越来越低，通过对各业务线的梳理，共性业务的提取、整合、统一，为各业务快速引用、建设提供了有效支撑。",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E5%BE%B7%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%8A%80%E6%9C%AF%E4%B8%AD%E5%8F%B0%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        msg: "-",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E5%BE%B7%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%8A%80%E6%9C%AF%E4%B8%AD%E5%8F%B0%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        msg: "-",
      },
    },
    {
      content1: {
        title: "某日系汽车品牌智能客服系统",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        msg:
          "为了更好的接待客户，为品牌方进行了客服系统的升级建设，包含“机器人服务、语音识别、文字识别、关键信息提取、标签化、知识库建设”等支持。",
      },
      content2: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        msg: "-",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        msg: "-",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        msg: "-",
      },
    },
  ],
  EN: [
    {
      content1: {
        title: "OneID, a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8COneID.png",
        msg:
          "The brand creates a new business model for mobile travel services. OneID helps brands break data islands, link all touch points, integrate global users, and become the cornerstone of a unified information platform for brand building.",
      },
      content2: {
        title: "A Japanese used car platform",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E4%BA%8C%E6%89%8B%E8%BD%A6%E5%B9%B3%E5%8F%B0.png",
        msg:
          "With the saturation of the new car market, the Chinese car market has changed from an increase to an era of stock. In order to improve customer service, the brand has created a used car platform to help car owners better purchase vehicles.",
      },
      content3: {
        title: "Social Construction of a Korean Department",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E9%9F%A9%E7%B3%BB%E7%A4%BE%E4%BA%A4%E5%BB%BA%E8%AE%BE.png",
        msg:
          "An intelligent service platform integrating car service/car life/social interaction built around the entire life cycle of users promotes the conversion of users to fans, achieves word-of-mouth and sales fission, and enhances brand value.",
      },
      content4: {
        title: "Social Construction of a Korean Department",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%94%B5%E5%AD%90%E5%B1%95%E6%9E%B6%E7%BB%AD%E8%88%AA%E5%85%85%E7%94%B5%E6%9F%9C.png",
        msg:
          "An intelligent service platform integrating car service/car life/social interaction built around the entire life cycle of users promotes the conversion of users to fans, achieves word-of-mouth and sales fission, and enhances brand value.",
      },
    },
    {
      content1: {
        title:
          "Benz digital showroom software and hardware system construction",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E5%A5%94%E9%A9%B0%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E8%BD%AF%E7%A1%AC%E4%BB%B6%E7%B3%BB%E7%BB%9F%E5%BB%BA%E8%AE%BE.png",
        msg:
          "With the development of the times, a new generation of consumers have higher requirements for the experience of offline stores. In order to improve the experience of customers to the store, Mercedes-Benz has carried out the digital and intelligent upgrade of the entire store’s software and hardware.",
      },
      content2: {
        title:
          "Construction of a digital showroom for a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E5%BB%BA%E8%AE%BE.png",
        msg:
          "With the continuous advancement of technology, a comprehensive upgrade of the digital showroom experience has been carried out for the brand, giving customers a close-to-real car-watching experience in the case of uneven car models in the store.",
      },
      content3: {
        title:
          "Construction of a digital showroom for a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E5%8C%97%E6%B1%BD%E6%96%B0%E8%83%BD%E6%BA%90%E9%87%91%E6%B8%AF%E5%B1%95%E5%8E%85.png",
        msg:
          "With the continuous advancement of technology, a comprehensive upgrade of the digital showroom experience has been carried out for the brand, giving customers a close-to-real car-watching experience in the case of uneven car models in the store.",
      },
      content4: {
        title:
          "Construction of a digital showroom for a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/VTI%E4%BA%BA%E8%84%B8%E8%AF%86%E5%88%AB%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        msg:
          "With the continuous advancement of technology, a comprehensive upgrade of the digital showroom experience has been carried out for the brand, giving customers a close-to-real car-watching experience in the case of uneven car models in the store.",
      },
    },
    {
      content1: {
        title:
          "Membership level rights and interests system of a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7%E6%9D%83%E7%9B%8A%E4%BD%93%E7%B3%BB.png",
        msg:
          "In order to enhance the car service for brand members, with the support of OneID, a unified level and corresponding rights services have been built for all brand contacts.",
      },
      content2: {
        title: "Points system for a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
        msg:
          'In order to enhance the activity and value of contact users, the construction of a point system, including the construction of "point acquisition event definition, point management, point mall" and other systems.',
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
        msg: "-",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
        msg: "-",
      },
    },
    {
      content1: {
        title: "Digital key system of a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E9%92%A5%E5%8C%99%E7%B3%BB%E7%BB%9F.png",
        msg: `In order to better improve the customer's car experience and car sharing services, help the brand to carry out the construction of the user terminal of the digital key system, including "key circulation, sharing with relatives and friends, key recycling", etc.`,
      },
      content2: {
        title: "ii.	A certain Japanese luxury car brand hospitality service",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E5%BE%85%E5%AE%A2%E5%85%A5%E5%8E%82%E6%9C%8D%E5%8A%A1.png",
        msg:
          "Value-added services based on the digital key, the owner can use the digital key to authorize the vehicle to operate the vehicle without the key to provide services such as waiting for customers and returning to the factory for maintenance/charging.",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E5%BE%85%E5%AE%A2%E5%85%A5%E5%8E%82%E6%9C%8D%E5%8A%A1.png",
        msg: "-",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E5%BE%85%E5%AE%A2%E5%85%A5%E5%8E%82%E6%9C%8D%E5%8A%A1.png",
        msg: "-",
      },
    },
    {
      content1: {
        title: "Volvo SCRM",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/Volvo%20SCRM.png",
        msg:
          "Relying on data, through service support, marketing support, and data support, we can cover the whole life cycle of customer traffic and conversion, and connect online and offline to aggregate customers to achieve brand co-creation.",
      },
      content2: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%88%86%E6%9E%90.png",
        msg: "-",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%B9%BE%E6%B5%81%E6%99%BA%E8%83%BDCRM%E6%A1%88%E4%BE%8B%E9%9B%86.png",
        msg: "-",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E5%8C%97%E6%B1%BD%E6%8E%A8%E8%8D%90%E5%B1%95%E7%A4%BA%E6%96%B9%E6%A1%88.png",
        msg: "-",
      },
    },
    {
      content1: {
        title: "Data center solution of a Japanese luxury automobile brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%8F%B0%E6%96%B9%E6%A1%88.png",
        msg:
          "the data of each contact is separated, which leads to the data value cannot be maximized and commercialized. Through the support of OneID, combined with the construction of data governance, data modeling and business demand support, the data value can be maximized.",
      },
      content2: {
        title:
          "Mid-office solution for a certain German luxury car brand busines",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E5%BE%B7%E7%B3%BB%E8%B1%AA%E5%8D%8E%E8%BD%A6%E5%93%81%E7%89%8C%E4%B8%9A%E5%8A%A1%E4%B8%AD%E5%8F%B0%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        msg:
          "With the development of brand business, the cost of independent construction and maintenance of each business is increasing day by day and the efficiency is getting lower and lower. Through sorting out each business line and extracting, integrating and unifying the common business, it provides effective support for the rapid reference and construction of each business.",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E5%BE%B7%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%8A%80%E6%9C%AF%E4%B8%AD%E5%8F%B0%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        msg: "-",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E5%BE%B7%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%8A%80%E6%9C%AF%E4%B8%AD%E5%8F%B0%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        msg: "-",
      },
    },
    {
      content1: {
        title:
          "Intelligent customer service system of a Japanese automobile brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        msg: `In order to better receive customers, the customer service system was upgraded and constructed for the brand, including "robot service, voice recognition, text recognition, key information extraction, labeling, knowledge base construction" and other support.`,
      },
      content2: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        msg: "-",
      },
      content3: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        msg: "-",
      },
      content4: {
        title: "",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        msg: "-",
      },
    },
  ],
};

export default info;

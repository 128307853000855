<template>
    <div>
      <nav-header></nav-header>
        <router-view></router-view>
      <nav-footer></nav-footer>
    </div>
</template>

<script>
import NavHeader from "components/NavHeader";
import MobileNavHeader from "components/MobileNavHeader";
import NavFooter from "components/NavFooter";

export default {
  name: "PCHome",
  components: {
    NavHeader,
    MobileNavHeader,
    NavFooter,
  },
  data() {
    return {
      ismOrpc: "PCoperation", //默认展示pc
    };
  },
  methods: {
    //判断
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  created() {
    if (this._isMobile()) {
      //手机端
      this.ismOrpc = "Moperation";
      window.onload = function() {
        getRem(750, 100);
      };
      window.onresize = function() {
        getRem(750, 100);
      };
      function getRem(pwidth, prem) {
        var html = document.getElementsByTagName("html")[0];
        var oWidth =
          document.body.clientWidth || document.documentElement.clientWidth;
        html.style.fontSize = (oWidth / pwidth) * prem + "px";
      }
      this.$store.commit("checkEq", "m");
      this.$router.replace("/Mobile");
    } else {
      //pc端
      this.ismOrpc = "PCoperation";
      this.$store.commit("checkEq", "pc");
    }
  },
};
</script>
<style lang="scss" scoped>
</style>


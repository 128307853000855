import TopBar from "./data/topBar";
import newsMsg from "./data/newsMsg";
import serveProMsg from "./data/serveProMsg";
import serverMsg from "./data/serverMsg";
import casesMsg from "./data/casesMsg";
import casesShowMsg from "./data/casesShowMsg";
import productMsg from "./data/productMsg";

const en = {
  language: {
    name: "English",
  },
  topBar: TopBar.EN,
  newsMsg: newsMsg.EN,
  serveProMsg: serveProMsg.EN,
  serverMsg: serverMsg.EN,
  casesMsg: casesMsg.EN,
  casesShowMsg: casesShowMsg.EN,
  productMsg: productMsg.EN,
  xzcy: "Download brochure",
  ljxq: "Learn more",
  copyRight:
    "Copyright Gulfstream (Beijing), ",
  aboutBtnName: ["Events", "Profile", "Culture", "Qualification"],
  aboutMsg: {
    companyProfile: `Gulfstream (Beijing) Intelligent Technology Co., Ltd. is located in Beijing Zhongguancun Dongsheng Science and Technology Park, is a dedicated to provide customers with comprehensive digital display related services, to promote the digital transformation of the industry and digital marketing transformation of the comprehensive solution provider, the national method of high-tech enterprise certification qualification. Core solutions cover intelligent audio and video system integration, digital twin environment architecture, integrated big data trust transmission, AR/VR interactive video wall, machine vision/voice, commercial service robots and other fields, with more than ten software and hardware products covering independent intellectual property rights. The company has long maintained close cooperation with many international enterprises, and is the authorized partner of Panasonic, Toshiba, Canon, Samsung, Philips, Kuicong, ATLAS and other manufacturers. .
companyCulture:
Gulfstream (Beijing) Intelligent Technology Co., Ltd. is located in Beijing Zhongguancun Dongsheng Science and Technology Park, is a dedicated to provide customers with comprehensive digital display related services, to promote the digital transformation of the industry and digital marketing transformation of the comprehensive solution provider, the national method of high-tech enterprise certification qualification. Core solutions cover intelligent audio and video system integration, digital twin environment architecture, integrated big data trust transmission, AR/VR interactive video wall, machine vision/voice, commercial service robots and other fields, with more than ten software and hardware products covering independent intellectual property rights. The company has long maintained close cooperation with many international enterprises, and is the authorized partner of Panasonic, Toshiba, Canon, Samsung, Philips, Kuicong, ATLAS and other manufacturers.`,
    companyCulture: `Gulfstream (Beijing) Intelligent Technology Co., Ltd. is located in Beijing Zhongguancun Dongsheng Science and Technology Park, is a dedicated to provide customers with comprehensive digital display related services, to promote the digital transformation of the industry and digital marketing transformation of the comprehensive solution provider, the national method of high-tech enterprise certification qualification. Core solutions cover intelligent audio and video system integration, digital twin environment architecture, integrated big data trust transmission, AR/VR interactive video wall, machine vision/voice, commercial service robots and other fields, with more than ten software and hardware products covering independent intellectual property rights. The company has long maintained close cooperation with many international enterprises, and is the authorized partner of Panasonic, Toshiba, Canon, Samsung, Philips, Kuicong, ATLAS and other manufacturers. .
companyCulture:
Gulfstream (Beijing) Intelligent Technology Co., Ltd. is located in Beijing Zhongguancun Dongsheng Science and Technology Park, is a dedicated to provide customers with comprehensive digital display related services, to promote the digital transformation of the industry and digital marketing transformation of the comprehensive solution provider, the national method of high-tech enterprise certification qualification. Core solutions cover intelligent audio and video system integration, digital twin environment architecture, integrated big data trust transmission, AR/VR interactive video wall, machine vision/voice, commercial service robots and other fields, with more than ten software and hardware products covering independent intellectual property rights. The company has long maintained close cooperation with many international enterprises, and is the authorized partner of Panasonic, Toshiba, Canon, Samsung, Philips, Kuicong, ATLAS and other manufacturers.`,
    title: "CONTACT US",
    phone: "Contact number:010-58458282",
    email: "Email address:mail@valuetech.com.cn",
    address:
      "10-103, Building A10, Accelerator Substation, Dongsheng Science and Technology Park, Anningzhuanghou Street, Haidian District, Beijing",
    mapModalTitle: "The Gulf Stream company",
    mapModalContainer:
      "Building A10 10-103, Accelerator Substation, Dongsheng Science and Technology Park, Anningzhuanghou Street, Haidian District, Beijing",
  },
};

export default en;

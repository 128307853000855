<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
      this.$i18n.locale = JSON.parse(sessionStorage.getItem("store")).lang;
    } else {
      this.$i18n.locale = "cn";
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", (event) => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>
<style lang="scss">
.van-dropdown-menu__title::after {
  opacity: 0 !important;
}
.van-dropdown-menu__bar {
  background-color: rgba($color: #000000, $alpha: 0) !important;
}
</style>

<template>
    <div id="news">
      <nav-header v-if="this.eq != 'm'">
        {{title}}
      </nav-header>
      <MobileNavHeader v-else></MobileNavHeader>
      <h3 class="text_ov_el" >{{title}}</h3>
      <p class="text_l3">{{createdAt}}</p>
        <el-row type="flex" justify="center">
          <el-col  :xs="20" :sm="20" :md="10" :lg="10" :xl="10">
            <div v-html="pageContent" class="newsContainer"></div>
          </el-col>
        </el-row>
      <nav-footer></nav-footer>
    </div>
</template>
<script>
import { newsContentApi } from "@/request/api.js";
import NavHeader from "components/NavHeader";
import MobileNavHeader from "components/MobileNavHeader";
import NavFooter from "components/NavFooter";

export default {
  name: "News",
  data() {
    return {
      pageContent: "",
      title: "",
      createdAt: "",
      eq: ""
    };
  },
  components: {
    NavHeader,
    MobileNavHeader,
    NavFooter
  },
  mounted() {
    const id = this.$route.query.pageIndex;
    this.eq = this.$route.query.eq;
    newsContentApi(id).then(res => {
      this.pageContent = res.data.content;
      this.title = res.data.title;
      this.createdAt = res.data.createdAt.slice(0, 10);
    });
  }
};
</script>
<style lang="scss" scoped>
#news {
  min-height: 1100px;
  margin-top: 120px;
  padding: 25px 0 0 0;
}
.newsContainer {
  min-height: 1100px;
}
.text_ov_el {
  text-align: center;
}
.text_l3 {
  text-align: center;
}

@media screen and(max-width:1200px) {
  #news {
    min-height: 800px;
    margin-top: 50px;
    padding: 25px 0 0 0;
  }
  .newsContainer {
    min-height: 800px;
  }
}
</style>
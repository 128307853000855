<template>
    <div class="header">
        <el-row class="nav-topbar">
            <el-col :xs="4" :sm="4" :md="8" :lg="12" :xl="12">
                <van-dropdown-menu class="menu">
                <van-dropdown-item ref="navmenu" @change="navChange" v-model="value1" :options="option1">
                    <template #title="props">
                        <van-icon class="menu-icon" name="bars" color="#fff" />
                    </template>
                </van-dropdown-item>
                </van-dropdown-menu>
            </el-col>
            <el-col :xs="15" :sm="16" :md="4" :lg="8" :xl="8">
                <div class="logo" v-if="!pageTitle"><img src="http://cdn.valuetech.com.cn/images/logo.png"></div>
                <h2 class="pageTitle" v-else>{{pageTitle}}</h2>
            </el-col>
            <el-col :xs="5" :sm="4" :md="8" :lg="4" :xl="4">
                <div class="checkBtn" @click="changeLanguage()"><span :class="{'btnActive':btnIsActive}">CN</span>&nbsp;|&nbsp;<span :class="{'btnActive':!btnIsActive}">EN</span></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Bus from "@/utils/bus.js";

export default {
  data() {
    return {
      value1: "mindex",
      pageTitle: "",
      btnIsActive: true,
      option1: [
        { text: "Home  首页", value: "mindex" },
        { text: "Service items  服务项目", value: "servicesAvailable" },
        { text: "Product  自主产品", value: "independentProduct" },
        { text: "Case study  案例展示", value: "caseShows" },
        // { text: "Information  新闻中心", value: "newsCenter" },
        { text: "About us  关于我们", value: "about" },
      ],
    };
  },
  methods: {
    navChange(value) {
      this.$router.push(value);
      switch (value) {
        case "mindex":
          this.pageTitle = "";
          break;
        case "servicesAvailable":
          this.pageTitle = "服务项目";
          break;
        case "independentProduct":
          this.pageTitle = "自主产品";
          break;
        case "caseShows":
          this.pageTitle = "案例展示";
          break;
        // case "newsCenter":
        //   this.pageTitle = "新闻中心";
        //   break;
        case "about":
          this.pageTitle = "关于我们";
          break;
      }
    },
    changeLanguage() {
      this.btnIsActive = !this.btnIsActive;
      this.$i18n.locale == "cn"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "cn");
      localStorage.setItem("languageSet", this.$i18n.locale);
      this.$store.commit("changeLang");
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    },
  },
  mounted() {
    Bus.$on("changeMNav", (routerName) => {
      this.value1 = routerName;
      switch (this.value1) {
        case "mindex":
          this.pageTitle = "";
          break;
        case "servicesAvailable":
          this.pageTitle = "服务项目";
          break;
        case "independentProduct":
          this.pageTitle = "自主产品";
          break;
        case "caseShows":
          this.pageTitle = "案例展示";
          break;
        // case "newsCenter":
        //   this.pageTitle = "新闻中心";
        //   break;
        case "about":
          this.pageTitle = "关于我们";
          break;
      }
    });
    if (sessionStorage.getItem("navState")) {
      Bus.$emit(
        "changeMNav",
        sessionStorage.getItem("navState").replace("mobile/", "")
      );
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/mixin.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/global.scss";

.pageTitle {
  color: #fff;
}

.header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  z-index: 99;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ccc;
  text-align: center;
  z-index: 111;
  .nav-topbar {
    .menu {
      //   height: 70px;
      margin-top: 5px;
      .menu-icon {
        position: relative;
        font-size: 25px;
      }
    }

    .logo {
      height: 25px;
    }
  }
  .checkBtn {
    font-size: 15px;
    cursor: pointer;
    .btnActive {
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
<template>
    <div>
      <mobile-nav-header></mobile-nav-header>
        <router-view></router-view>
      <nav-footer></nav-footer>
    </div>
</template>

<script>
import MobileNavHeader from "components/MobileNavHeader";
import NavFooter from "components/NavFooter";

export default {
  name: "MHome",
  components: {
    MobileNavHeader,
    NavFooter,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
</style>


const info = {
  ZH: {
    n1: "首页",
    n2: "服务项目",
    n3: "自主产品",
    n4: "案例展示",
    n5: "新闻中心",
    n6: "关于我们",
    n7: "服务能力",
  },
  EN: {
    n1: "Home",
    n2: "Service",
    n3: "Patent",
    n4: "Case",
    n5: "News",
    n6: "About",
    n7: "Ability",
  },
};
export default info;

const info = {
  ZH: [
    {
      title: "数字化基础建设",
      cardImgUrl: require("assets/images/图层 588.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "实现车企降本增效，并通过与用户直接接触和运营，变革自身盈利模式，对于用户来说，能够大幅优化购车、用车体验。",
      },
      other: [
        {
          title: "OneID",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/oneID.png",
          itemMsg: `OneID以“全面、准确、统一、安全”的智能大数据体系为支撑，承载连接客户、品牌、经销商的作用，将成为品牌未来移动出行商业模式的基盘。`,
        },
        {
          title: "粉丝社区",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%B2%89%E4%B8%9D%E7%A4%BE%E5%8C%BA.png",
          itemMsg:
            "吸引和汇聚品牌粉丝群体的社区平台，利用粉丝传导，实现粉丝效应的价值转化，粉丝社区，最早是以明星个人为核心、以娱乐为话题的互动社区。随着互联网思维在企业领域的渗透，社会化消费者的崛起，粉丝经济逐渐成为企业关注和青睐的商业模式，“粉丝社区”由此应运而生。",
        },
        {
          title: "电商平台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%94%B5%E5%95%86%E5%B9%B3%E5%8F%B0.png",
          itemMsg:
            "现在车辆购买几乎都是从网上搜索开始的，因为广大客户已经习惯了网上订票的线下服务模式！在线快速选配预定线下交付已逐渐成为大家的习惯。",
        },
        {
          title: "经销商管理系统",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%BB%8F%E9%94%80%E5%95%86%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F.png",
          itemMsg:
            "现在车辆购买几乎都是从网上搜索开始的，因为广大客户已经习惯了网上订票的线下服务模式。",
        },
      ],
    },
    {
      title: "数字化展厅",
      cardImgUrl: require("assets/images/图层 591.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85.png",
        itemMsg:
          "通过智能化软硬件的引入建设，对展厅进行统一赋能，为客户提供智能互动、智能引导、智能查询等服务，有效提升了客户的服务体验。",
      },
      other: [
        {
          title: "互动大屏",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E4%BA%92%E5%8A%A8%E5%A4%A7%E5%B1%8F.png",
          itemMsg:
            "利用最先进的3D/VR/AI技术，软硬件结合，为客户提供更加激动人心的服务体验，彰显品牌的豪华感/科技感，支持客户选车配车并进行配置保留、分享。",
        },
        {
          title: "电子价格牌",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E7%94%B5%E5%AD%90%E4%BB%B7%E6%A0%BC%E7%89%8C.png",
          itemMsg:
            "支持大规模、零改造落地，可作为线下场景中客户交互及数据收集、反馈的重要触点，软硬件结合，长续航保证内容的展示和客户的体验。",
        },
        {
          title: "智慧听鉴",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%99%BA%E6%85%A7%E5%90%AC%E9%89%B4.png",
          itemMsg:
            "离线录音、角色识别、在线解析按角色对话翻译、智能分析、质量评定、客户洞察，销售接待过程中，实时反馈客户情况帮助销售服务的精准度提升销售服务能力。",
        },
        {
          title: "智能会议室",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%99%BA%E8%83%BD%E4%BC%9A%E8%AE%AE%E5%AE%A4.jpg",
          itemMsg:
            "会议室预定、电话会议、视频会议、网络会议以及门禁等硬件设备整合，打造企业智能会议室，帮助企业进行会议室资源精细化管理，提升会议室资源的利用率。",
        },
      ],
    },
    {
      title: "会员体系建设",
      cardImgUrl: require("assets/images/组 3.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB.png",
        itemMsg:
          "作为客户旅程中的关键元素之一，在品牌与客户/潜在客户互动的生态系统中扮演着非常重要的角色！是管理品牌会员机制及其日常运营的核心门户。",
      },
      other: [
        {
          title: "会员等级权益体系",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7%E6%9D%83%E7%9B%8A%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "等级&权益作为会员体系的核心业务，主要针对车主会员，通过权益的输出，提升车主的忠诚度和粘度，权益主要涉及“到店尊享”“异业服务”“权益兑换”等。",
        },
        {
          title: "会员积分体系",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "包含积分发放、积分价值兑换，通过积分商城商品价值的吸引力，促进会员活跃度/积极性，通过积分商品或服务的兑换，促进会员消费，从而创造基于会员体系的营销价值。",
        },
        {
          title: "会员营销体系",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E8%90%A5%E9%94%80%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "卡券中心、活动引擎结合会员等级权益、会员积分业务，搭建会员业务统一能力输出的运营平台，通过活动的吸引以及卡券的激励，提升用户消费转化。",
        },
      ],
    },
    {
      title: "车联网",
      cardImgUrl: require("assets/images/资源 1.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%81%94%E7%BD%91.png",
        itemMsg:
          "提供车与车、车与人、人与人移动互联整体解决方案，包括数字钥匙、车载终端应用等解决方案。",
      },
      other: [
        {
          title: "数字钥匙",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%95%B0%E5%AD%97%E9%92%A5%E5%8C%99.png",
          itemMsg:
            "结合车辆、移动终端、云服务三端，三位一体的车辆数字钥匙保护体系，支持车主无钥匙用车、家人朋友授权用车、代驾授权维保等业务支持，提升数字化用车体验。",
        },
        {
          title: "车载终端应用",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%BD%BD%E7%BB%88%E7%AB%AF%E5%BA%94%E7%94%A8.png",
          itemMsg:
            "基于车联网技术，通过与车辆终端数据中心的对接，利用云计算服务，构建车辆画像，形成云端数字资产，分析和处理，对车辆故障提前预知，降低事故率提升用车体验，提升品牌竞争力。",
        },
        {
          title: "车联网云服务",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%81%94%E7%BD%91%E4%BA%91%E6%9C%8D%E5%8A%A1.png",
          itemMsg:
            "基于车联网技术，通过与车辆终端数据中心的对接，利用云计算服务，构建车辆画像，形成云端数字资产，分析和处理，对车辆故障提前预知，降低事故率提升用车体验，提升品牌竞争力。",
        },
      ],
    },
    {
      title: "数字化营销",
      cardImgUrl: require("assets/images/矢量智能对象(1).png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80.png",
        itemMsg:
          "提供从流量引入-流量池建设-活动营销-线索生成-线索库建立到线索持续挖掘转化全流程数字营销解决方案。",
      },
      other: [
        {
          title: "SCRM",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/scrm.png",
          itemMsg:
            "覆盖客户全生命周期的引流转化, 线上联动线下, 聚合客户的社交连接，以用户服务为导向，帮助销售为客户提供精细化服务及精准培育，提升转化。",
        },
        {
          title: "用户成长系统",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E7%94%A8%E6%88%B7%E6%88%90%E9%95%BF%E7%B3%BB%E7%BB%9F.png",
          itemMsg:
            "建立用户详细的分层数据模型，通过对用户画像的构建及分层管理，进行用户精细化管理，促进用户成长。",
        },
        {
          title: "线索库建设",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E7%BA%BF%E7%B4%A2%E5%BA%93.png",
          itemMsg:
            "通过用户成长系统+SCRM对用户各阶段的不断培育和服务，提升用户意向，促进用户线索转化，通过对线索的跟进管理，促进用户转化。",
        },
        {
          title: "数字分析平台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%95%B0%E5%AD%97%E5%88%86%E6%9E%90%E5%B9%B3%E5%8F%B0.png",
          itemMsg:
            "提供全模块全场景的数据埋点、数据收集汇总、数据提取/清洗/整合以及数据洞察、业务分析报告输出的全业务支持，帮助品牌提升业务能力和品牌竞争力。",
        },
      ],
    },
    {
      title: "中台建设",
      cardImgUrl: require("assets/images/图层 608 拷贝.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "提升企业数据采集、计算、存储、加工能力，提升企业业务集成、体验、运营、对接的能力，提升企业技术快速支持的能力。",
      },
      other: [
        {
          title: "数据中台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "帮助企业从数据汇聚、数据治理、数据提炼、数据建模、数据资产可视化服务赋能的持续数据资产化的建设支持，帮助品牌实现数据资产价值最大化。",
        },
        {
          title: "业务中台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E4%B8%9A%E5%8A%A1%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "通过对各业务可共享可复用具有共性属性的抽取提炼，帮助企业新业务的快速建设、降低重复建设成本、统一用户体验，提升业务的运营能力。",
        },
        {
          title: "技术中台",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%8A%80%E6%9C%AF%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "通过对各业务可共享可复用具有共性属性的服务抽取，进行微服务的建设，通过微服务的赋能，提升业务支持的技术能力。",
        },
      ],
    },
    {
      title: "AI客服中心",
      cardImgUrl: require("assets/images/矢量智能对象.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83.png",
        itemMsg:
          "采用机器人+人工双重服务模式，智能机器人解决常见问题，人工坐席解决个性化问题，降本提效同时提升客户体验。",
      },
      other: [
        {
          title: "文本识别服务",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%96%87%E6%9C%AC%E8%AF%86%E5%88%AB%E6%9C%8D%E5%8A%A1.png",
          itemMsg:
            "通过对文字的识别，及语义的理解，识别对话文本内容，通过对关键字词的提取，帮助客服更针对性的提供精准服务。",
        },
        {
          title: "语音识别服务",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E8%AF%AD%E9%9F%B3%E8%AF%86%E5%88%AB%E6%9C%8D%E5%8A%A1.png",
          itemMsg:
            "通过对语音收集、解析、语义识别等智能语音服务，通过对关键字词的提取，帮助客服更针对性的提供精准服务。",
        },
        {
          title: "AI自学习",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E8%87%AA%E4%B8%BB%E5%AD%A6%E4%B9%A0.png",
          itemMsg:
            "通过对客服接待过程中相关内容的识别和关键字词的沉淀，以及机器人服务过程中遇到的问题提取和归因，通过匹配人工客服的解决方案，不断的学习提升服务能力。",
        },
        {
          title: "知识库",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E7%9F%A5%E8%AF%86%E5%BA%93.png",
          itemMsg:
            "通过品牌渠道内容资源的整合，丰富只是内容库，结合客服接待过程中的不断完善，并进行内容资产标签化，帮助销售更精准提供服务。",
        },
      ],
    },
  ],
  EN: [
    {
      title: "Digital infrastructure",
      cardImgUrl: require("assets/images/图层 588.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "Through direct contact with users and operation, it can change its own profit model, and greatly optimize the experience of car purchase and use for users.",
      },
      other: [
        {
          title: "OneID",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/oneID.png",
          itemMsg: `OneID is supported by a "comprehensive, accurate, unified, and secure" intelligent big data system, and it bears the role of connecting customers, brands, and distributors. It will become the foundation of the brand's future mobile travel business model.`,
        },
        {
          title: "Fan community",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%B2%89%E4%B8%9D%E7%A4%BE%E5%8C%BA.png",
          itemMsg: `The community platform that attracts and gathers brand fan groups uses fan transmission to realize the value transformation of fan effect. The fan community was originally an interactive community with celebrities as the core and entertainment as the topic. With the penetration of Internet thinking in the corporate field and the rise of social consumers, the fan economy has gradually become a business model that companies pay attention to and favor, and a "fan community" has emerged from this.`,
        },
        {
          title: "Electronic business platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%94%B5%E5%95%86%E5%B9%B3%E5%8F%B0.png",
          itemMsg:
            "Nowadays, vehicle purchases almost always start from online search, because the majority of customers have become accustomed to the offline service mode of online booking, and fast online selection and reservation of offline delivery have gradually become everyone’s habit.",
        },
        {
          title: "Dealer Management System",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%BB%8F%E9%94%80%E5%95%86%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F.png",
          itemMsg:
            "Almost all vehicle purchases now start from online search, because the majority of customers have become accustomed to the offline service model of online booking.",
        },
      ],
    },
    {
      title: "Digital Exhibition Hall",
      cardImgUrl: require("assets/images/图层 591.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85.png",
        itemMsg:
          "Through the introduction and construction of intelligent hardware and software, the exhibition hall is unified and empowered to provide customers with intelligent interaction, intelligent guidance, intelligent inquiry and other services, which effectively improves customer service experience.",
      },
      other: [
        {
          title: "Interactive large screen",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E4%BA%92%E5%8A%A8%E5%A4%A7%E5%B1%8F.png",
          itemMsg:
            "Using the most advanced 3D, VR, AI technology, and the combination of software and hardware, we provide customers with a more exciting service experience, highlight the brand's sense of luxury and technology, and support customers in choosing cars and matching cars, and carrying out configuration reservations and sharing.",
        },
        {
          title: "Electronic price tag",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E7%94%B5%E5%AD%90%E4%BB%B7%E6%A0%BC%E7%89%8C.png",
          itemMsg:
            "It supports large-scale, zero-renovation landing, and can be used as an important touch point for customer interaction, data collection, and feedback in offline scenarios. The combination of software and hardware ensures long-term battery life to ensure content display and customer experience.",
        },
        {
          title: "Listen to wisdom",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%99%BA%E6%85%A7%E5%90%AC%E9%89%B4.png",
          itemMsg:
            "Offline recording, role recognition, online analysis, dialogue translation by role, intelligent analysis, quality assessment, customer insight, real-time feedback on customer conditions during the sales reception process to help the accuracy of sales services improve sales service capabilities.",
        },
        {
          title: "Smart meeting room",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%99%BA%E8%83%BD%E4%BC%9A%E8%AE%AE%E5%AE%A4.jpg",
          itemMsg:
            "Conference room reservations, teleconferences, video conferences, network conferences, access control and other hardware devices are integrated to create smart meeting rooms for enterprises, help enterprises carry out refined management of meeting room resources, and improve the utilization rate of meeting room resources.",
        },
      ],
    },
    {
      title: "Member System",
      cardImgUrl: require("assets/images/组 3.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB.png",
        itemMsg:
          "As one of the key elements in the customer journey, it plays a very important role in the ecosystem where the brand interacts with customers/potential customers! It is the core portal to manage the brand membership mechanism and its daily operations.",
      },
      other: [
        {
          title: "Membership level rights system",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7%E6%9D%83%E7%9B%8A%E4%BD%93%E7%B3%BB.png",
          itemMsg: `As the core business of the membership system, levels and rights and interests are mainly aimed at car owner members. Through the export of rights and interests, the loyalty and viscosity of car owners are improved. Rights and interests mainly involve "in-store exclusive", "inter-industry services", and "rights exchange".`,
        },
        {
          title: "Member Points System",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "Including points issuance, point value exchange, through the attractiveness of the value of points mall products, promote member activity and enthusiasm, through the exchange of points goods or services, promote member consumption, thereby creating marketing value based on the membership system.",
        },
        {
          title: "Member marketing system",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E4%BC%9A%E5%91%98%E8%90%A5%E9%94%80%E4%BD%93%E7%B3%BB.png",
          itemMsg:
            "The card and voucher center and event engine combine membership level rights and membership points business to build an operating platform for the output of unified capabilities of member services. Through the attraction of activities and the incentives of cards and coupons, the conversion of user consumption can be improved.",
        },
      ],
    },
    {
      title: "Internet of Vehicles",
      cardImgUrl: require("assets/images/资源 1.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%81%94%E7%BD%91.png",
        itemMsg:
          "Provide overall solutions for the mobile interconnection of cars and cars, cars and people, and people and people, including solutions such as digital keys and in-vehicle terminal applications.",
      },
      other: [
        {
          title: "Digital key",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%95%B0%E5%AD%97%E9%92%A5%E5%8C%99.png",
          itemMsg:
            "Combining the three terminals of vehicles, mobile terminals, and cloud services, the three-in-one vehicle digital key protection system supports business support such as the owner's keyless car, the car authorized by family and friends, and the authorized maintenance on behalf of the driver to enhance the digital car experience.",
        },
        {
          title: "Internet of Vehicles Cloud Service",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%BD%BD%E7%BB%88%E7%AB%AF%E5%BA%94%E7%94%A8.png",
          itemMsg:
            "Based on the Internet of Vehicles technology, through the docking with the vehicle terminal data center, the use of cloud computing services to build vehicle portraits, form cloud digital assets, analyze and process, predict vehicle failures in advance, reduce accident rates, improve vehicle experience, and enhance brand competitiveness.",
        },
        {
          title: "Vehicle terminal application",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E8%BD%A6%E8%81%94%E7%BD%91%E4%BA%91%E6%9C%8D%E5%8A%A1.png",
          itemMsg:
            "Based on the characteristics of the in-vehicle central control, the application services of the in-vehicle central control are created, including: WeChat, QQ, music, FM, voice assistant and other smart in-vehicle applications and related services to enrich the car life.",
        },
      ],
    },
    {
      title: "Digital marketing",
      cardImgUrl: require("assets/images/矢量智能对象(1).png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80.png",
        itemMsg:
          "Provide full-process digital marketing solutions from traffic introduction, traffic pool construction, event marketing, clue generation, clue database establishment to clue continuous mining and conversion.",
      },
      other: [
        {
          title: "SCRM",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/scrm.png",
          itemMsg:
            "Covering the customer's full life cycle drainage and conversion, online linkage and offline, aggregate customer social connections, user service-oriented, help sales to provide customers with refined services and precise cultivation, and improve conversion.",
        },
        {
          title: "User growth system",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E7%94%A8%E6%88%B7%E6%88%90%E9%95%BF%E7%B3%BB%E7%BB%9F.png",
          itemMsg:
            "Establish a detailed hierarchical data model for users, and carry out refined user management through the construction of user portraits and hierarchical management to promote user growth.",
        },
        {
          title: "Clue library",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E7%BA%BF%E7%B4%A2%E5%BA%93.png",
          itemMsg:
            "Through the continuous cultivation and service of the user growth system + SCRM at all stages of the user, the user intention is improved, the conversion of user leads is promoted, and the user conversion is promoted through the follow-up management of the leads.",
        },
        {
          title: "Digital analysis platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%95%B0%E5%AD%97%E5%88%86%E6%9E%90%E5%B9%B3%E5%8F%B0.png",
          itemMsg:
            "Provide full-service support for data burying points, data collection and summary, data extraction/cleaning/integration, data insight and business analysis report output for all modules and scenarios, helping brands improve their business capabilities and brand competitiveness.",
        },
      ],
    },
    {
      title: "Middle Platform",
      cardImgUrl: require("assets/images/图层 608 拷贝.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "Enhance corporate data collection, calculation, storage, and processing capabilities, enhance corporate business integration, experience, operation, and docking capabilities, and enhance corporate technology rapid support capabilities.",
      },
      other: [
        {
          title: "Data middle platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "Help enterprises to support the construction of continuous data assetization from data aggregation, data governance, data extraction, data modeling, and data asset visualization services to help brands maximize the value of data assets.",
        },
        {
          title: "Business middle office",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E4%B8%9A%E5%8A%A1%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "By extracting and refining common attributes that can be shared and reused by each business, it helps enterprises to quickly build new businesses, reduce repetitive construction costs, unify user experience, and improve business operations capabilities.",
        },
        {
          title: "Technology Middle Platform",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%8A%80%E6%9C%AF%E4%B8%AD%E5%8F%B0.png",
          itemMsg:
            "Through the extraction of services with common attributes that can be shared and reused by each business, the construction of microservices is carried out, and the technical capabilities of business support can be improved through the empowerment of microservices.",
        },
      ],
    },
    {
      title: "AI Service",
      cardImgUrl: require("assets/images/矢量智能对象.png"),
      first: {
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83.png",
        itemMsg:
          "The dual service model of robot + manual is adopted, intelligent robots solve common problems, and artificial seats solve individualized problems, reducing costs and improving efficiency while improving customer experience.",
      },
      other: [
        {
          title: "Text recognition service",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%96%87%E6%9C%AC%E8%AF%86%E5%88%AB%E6%9C%8D%E5%8A%A1.png",
          itemMsg:
            "Through the recognition of text and the understanding of semantics, the content of dialogue text is recognized, and the extraction of key words helps customer service to provide more targeted and accurate services.",
        },
        {
          title: "Speech recognition service",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E8%AF%AD%E9%9F%B3%E8%AF%86%E5%88%AB%E6%9C%8D%E5%8A%A1.png",
          itemMsg:
            "Through intelligent voice services such as voice collection, analysis, and semantic recognition, and through the extraction of key words, help customer service to provide more targeted and accurate services.",
        },
        {
          title: "AI self-learning",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E8%87%AA%E4%B8%BB%E5%AD%A6%E4%B9%A0.png",
          itemMsg:
            "Through the identification of relevant content and the precipitation of key words in the process of customer service reception, as well as the extraction and attribution of problems encountered in the process of robot service, through the matching of manual customer service solutions, continuous learning and improvement of service capabilities.",
        },
        {
          title: "The knowledge base",
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E7%9F%A5%E8%AF%86%E5%BA%93.png",
          itemMsg:
            "Through the integration of brand channel content resources, enrich the content library, combine with the continuous improvement in the customer service reception process, and carry out content asset labeling to help sales provide more accurate services.",
        },
      ],
    },
  ],
};
export default info;

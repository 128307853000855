const info = {
  ZH: [
    [
      {
        title: "某日系豪华汽车品牌OneID",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8COneID.png",
        itemMsg:
          "品牌打造以移动出行服务为目的的全新商业模式，OneID帮助品牌方打破数据孤岛，链接全触点，统合全域用户，成为品牌建设一元化信息平台的基石。",
      },
      {
        title: "某日系二手车平台",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E4%BA%8C%E6%89%8B%E8%BD%A6%E5%B9%B3%E5%8F%B0.png",
        itemMsg:
          "随着新车市场的饱和，中国车市场由增量变为存量时代，品牌为了提升客户服务，打造了二手车平台，帮助车主更好的进行车辆的换购。",
      },
      {
        title: "某韩系社交建设",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E9%9F%A9%E7%B3%BB%E7%A4%BE%E4%BA%A4%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "围绕用户全生命周期搭建的用车服务/车生活/ 社交于一体的智能服务平台，促进用户向粉丝转化，实现口碑和销售裂变，提升品牌价值。",
      },
      {
        title: "电子展架续航充电柜",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%94%B5%E5%AD%90%E5%B1%95%E6%9E%B6%E7%BB%AD%E8%88%AA%E5%85%85%E7%94%B5%E6%9F%9C.png",
        itemMsg:
          "某德系豪华品牌全国经销店电子展架配置湾流智能充电柜解决续航问题，帮助店端提升可持续数字化服务体验。",
      },
    ],
    [
      {
        title: "奔驰数字展厅软硬件系统建设",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E5%A5%94%E9%A9%B0%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E8%BD%AF%E7%A1%AC%E4%BB%B6%E7%B3%BB%E7%BB%9F%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "随着时代的发展，新一代消费者对于线下门店的体验有着更高的要求，奔驰为了提升到店客户的体验进行了整店软硬件数字化、智能化升级。",
      },
      {
        title: "某日系豪华汽车品牌数字展厅建设",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "随着科技的不断进步，为品牌进行了数字展厅体验全面升级，店内车型不齐的情况下给客户接近真实的看车体验。",
      },
      {
        title: "某日系豪华汽车品牌数字展厅电子价格牌",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E7%94%B5%E5%AD%90%E4%BB%B7%E6%A0%BC%E7%89%8C.png",
        itemMsg:
          "利用最先进的3D/VR/AI技术，软硬件结合，为客户提供更加激动人心的服务体验，彰显品牌的豪华感、科技感，助力企业传递品牌内涵，有效提升品牌认知，让品牌理念更加深入人心。",
      },
      {
        title: "一汽解放数字展厅解决方案",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E4%B8%80%E6%B1%BD%E8%A7%A3%E6%94%BE%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        itemMsg:
          "随着时代发展，科技不断进步，新一代消费者对于线下门店的体验有着更高的要求，基于这种情况，湾流为一汽解放打造了基于数字展厅升级提升零售创新的解决方案。",
      },
      {
        title: "北汽新能源金港展厅",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E5%8C%97%E6%B1%BD%E6%96%B0%E8%83%BD%E6%BA%90%E9%87%91%E6%B8%AF%E5%B1%95%E5%8E%85.png",
        itemMsg:
          "涉及3D配车、电子展架、洽谈室改造、休息室数字化升级等展厅全方位数字化升级改造。",
      },
      {
        title: "VTI人脸识别解决方案",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/VTI%E4%BA%BA%E8%84%B8%E8%AF%86%E5%88%AB%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        itemMsg:
          "某德系豪华品牌经销店人脸识别解决方案，包括“访客身份识别、意向预知、接待优化、数据分析洞察”等业务支持。",
      },
    ],
    [
      {
        title: "某日系豪华汽车品牌会员等级权益体系",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7%E6%9D%83%E7%9B%8A%E4%BD%93%E7%B3%BB.png",
        itemMsg:
          "为了增强品牌会员的用车服务，在OneID的支持下，为品牌全触点建设了统一的等级和对应的权益服务。",
      },
      {
        title: "某日系豪华汽车品牌积分体系",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
        itemMsg:
          "为了增强触点用户的活跃和价值，进行了积分体系的建设，包含“积分获取事件定义、积分管理、积分商城”等体系的建设。",
      },
    ],
    [
      {
        title: "某日系豪华汽车品牌数字钥匙系统",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E9%92%A5%E5%8C%99%E7%B3%BB%E7%BB%9F.png",
        itemMsg:
          "为了更好的提升客户用车体验，以及车共享服务，帮助品牌进行了数字钥匙系统用户端的建设，包含“钥匙流转、亲友共享、钥匙回收”等。",
      },
      {
        title: "某日系豪华汽车品牌待客入厂服务",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E5%BE%85%E5%AE%A2%E5%85%A5%E5%8E%82%E6%9C%8D%E5%8A%A1.png",
        itemMsg:
          "基于数字钥匙的增值服务，车主通过数字钥匙授权的方式，代驾可无钥匙操作车辆进行待客返厂维保/充电等服务。",
      },
    ],
    [
      {
        title: "Volvo SCRM",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/Volvo%20SCRM.png",
        itemMsg:
          "以数据为依托，通过服务支持、营销支持、数据支持，覆盖客户户全生命周期引流转化，线上联动线下，聚合客户实现品牌共创。",
      },
      {
        title: "某日系豪华汽车品牌数据分析",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%88%86%E6%9E%90.png",
        itemMsg:
          "从场景埋点、数据提取、数据整合到数据分析、报告输出的业务全流程方案的规划建设的数据分析运营支持。",
      },
      {
        title: "北汽推荐展示方案",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E5%8C%97%E6%B1%BD%E6%8E%A8%E8%8D%90%E5%B1%95%E7%A4%BA%E6%96%B9%E6%A1%88.png",
        itemMsg:
          "主要构成“车辆数字展示台”“智能洽谈桌”“AR互动展示”“信息展示系统”4大模块提升品牌数字化营销能力。",
      },
    ],
    [
      {
        title: "某日系豪华汽车品牌数据中台方案",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%8F%B0%E6%96%B9%E6%A1%88.png",
        itemMsg:
          "各触点数据割裂，导致数据价值无法最大化、商业化，通过OneID的支持，结合数据治理、数据模型化、业务需求支持的建设，让数据价值最大化。",
      },
      {
        title: "某德系豪华车品牌业务中台解决方案",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E5%BE%B7%E7%B3%BB%E8%B1%AA%E5%8D%8E%E8%BD%A6%E5%93%81%E7%89%8C%E4%B8%9A%E5%8A%A1%E4%B8%AD%E5%8F%B0%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        itemMsg:
          "随着品牌业务的发展，各业务单独建设维护的成本日益升高，效率越来越低，通过对各业务线的梳理，共性业务的提取、整合、统一，为各业务快速引用、建设提供了有效支撑。",
      },
    ],
    [
      {
        title: "某日系汽车品牌智能客服系统",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        itemMsg:
          "为了更好的接待客户，为品牌方进行了客服系统的升级建设，包含“机器人服务、语音识别、文字识别、关键信息提取、标签化、知识库建设”等支持。",
      },
    ],
  ],
  EN: [
    [
      {
        title: "OneID, a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8COneID.png",
        itemMsg:
          "The brand creates a new business model for mobile travel services. OneID helps brands break data islands, link all touch points, integrate global users, and become the cornerstone of a unified information platform for brand building.",
      },
      {
        title: "A Japanese used car platform",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E4%BA%8C%E6%89%8B%E8%BD%A6%E5%B9%B3%E5%8F%B0.png",
        itemMsg:
          "With the saturation of the new car market, the Chinese car market has changed from an increase to an era of stock. In order to improve customer service, the brand has created a used car platform to help car owners better purchase vehicles.",
      },
      {
        title: "Social Construction of a Korean Department",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E6%9F%90%E9%9F%A9%E7%B3%BB%E7%A4%BE%E4%BA%A4%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "An intelligent service platform integrating car service/car life/social interaction built around the entire life cycle of users promotes the conversion of users to fans, achieves word-of-mouth and sales fission, and enhances brand value.",
      },
      {
        title: "Electronic display rack battery life charging cabinet",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/1-%E6%95%B0%E5%AD%97%E5%8C%96%E5%9F%BA%E7%A1%80%E5%BB%BA%E8%AE%BE/%E7%94%B5%E5%AD%90%E5%B1%95%E6%9E%B6%E7%BB%AD%E8%88%AA%E5%85%85%E7%94%B5%E6%9F%9C.png",
        itemMsg:
          "The electronic display rack of a German luxury brand's national dealership is equipped with a Gulfstream smart charging cabinet to solve the problem of battery life and help the store to improve the sustainable digital service experience.",
      },
    ],
    [
      {
        title:
          "Benz digital showroom software and hardware system construction",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E5%A5%94%E9%A9%B0%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E8%BD%AF%E7%A1%AC%E4%BB%B6%E7%B3%BB%E7%BB%9F%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "With the development of the times, a new generation of consumers have higher requirements for the experience of offline stores. In order to improve the experience of customers to the store, Mercedes-Benz has carried out the digital and intelligent upgrade of the entire store’s software and hardware.",
      },
      {
        title:
          "Construction of a digital showroom for a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E5%BB%BA%E8%AE%BE.png",
        itemMsg:
          "With the continuous advancement of technology, a comprehensive upgrade of the digital showroom experience has been carried out for the brand, giving customers a close-to-real car-watching experience in the case of uneven car models in the store.",
      },
      {
        title:
          "Digital showroom electronic price tag for a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E7%94%B5%E5%AD%90%E4%BB%B7%E6%A0%BC%E7%89%8C.png",
        itemMsg:
          "Using the most advanced 3D/VR/AI technology, the combination of software and hardware, to provide customers with a more exciting service experience, highlight the brand's sense of luxury and technology, and help companies pass on the brand connotation, effectively enhance brand awareness, and make the brand concept more Deeply rooted.",
      },
      {
        title: "FAW Jiefang Digital Showroom Solution",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E4%B8%80%E6%B1%BD%E8%A7%A3%E6%94%BE%E6%95%B0%E5%AD%97%E5%B1%95%E5%8E%85%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        itemMsg:
          "With the development of the times and the continuous advancement of science and technology, a new generation of consumers has higher requirements for the experience of offline stores. Based on this situation, Gulfstream has created a solution for FAW Jiefang to upgrade and enhance retail innovation based on digital showrooms.",
      },
      {
        title: "BAIC New Energy Golden Port Exhibition Hall",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/%E5%8C%97%E6%B1%BD%E6%96%B0%E8%83%BD%E6%BA%90%E9%87%91%E6%B8%AF%E5%B1%95%E5%8E%85.png",
        itemMsg:
          "Involving 3D car distribution, electronic display racks, negotiation room renovation, lounge digital upgrade and other comprehensive digital upgrades of the exhibition hall.",
      },
      {
        title: "VTI face recognition solution",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/2-%E6%95%B0%E5%AD%97%E5%8C%96%E5%B1%95%E5%8E%85/VTI%E4%BA%BA%E8%84%B8%E8%AF%86%E5%88%AB%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        itemMsg: `Facial recognition solution for a German luxury brand dealership, including business support such as "visitor identification, intention prediction, reception optimization, data analysis and insight".`,
      },
    ],
    [
      {
        title:
          "Membership level rights and interests system of a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7%E6%9D%83%E7%9B%8A%E4%BD%93%E7%B3%BB.png",
        itemMsg:
          "In order to enhance the car service for brand members, with the support of OneID, a unified level and corresponding rights services have been built for all brand contacts.",
      },
      {
        title: "Points system for a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/3-%E4%BC%9A%E5%91%98%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E7%A7%AF%E5%88%86%E4%BD%93%E7%B3%BB.png",
        itemMsg: `In order to enhance the activity and value of contact users, the construction of a point system, including the construction of "point acquisition event definition, point management, point mall" and other systems.`,
      },
    ],
    [
      {
        title: "Digital key system of a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E9%92%A5%E5%8C%99%E7%B3%BB%E7%BB%9F.png",
        itemMsg: `In order to better improve the customer's car experience and car sharing services, help the brand to carry out the construction of the user terminal of the digital key system, including "key circulation, sharing with relatives and friends, key recycling", etc.`,
      },
      {
        title: "A certain Japanese luxury car brand hospitality service",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/4-%E8%BD%A6%E8%81%94%E7%BD%91/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E5%BE%85%E5%AE%A2%E5%85%A5%E5%8E%82%E6%9C%8D%E5%8A%A1.png",
        itemMsg:
          "Value-added services based on the digital key, the owner can use the digital key to authorize the vehicle to operate the vehicle without the key to provide services such as waiting for customers and returning to the factory for maintenance/charging.",
      },
    ],
    [
      {
        title: "Volvo SCRM",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/Volvo%20SCRM.png",
        itemMsg:
          "Relying on data, through service support, marketing support, and data support, we can cover the whole life cycle of customer traffic and conversion, and connect online and offline to aggregate customers to achieve brand co-creation.",
      },
      {
        title: "Data analysis of a Japanese luxury car brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E5%AD%97%E5%88%86%E6%9E%90.png",
        itemMsg:
          "Data analysis and operation support for the planning and construction of the whole process plan of the business from scene embedding, data extraction, data integration to data analysis, and report output.",
      },
      {
        title: "BAIC recommended display plan",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/5-%E6%95%B0%E5%AD%97%E5%8C%96%E8%90%A5%E9%94%80/%E5%8C%97%E6%B1%BD%E6%8E%A8%E8%8D%90%E5%B1%95%E7%A4%BA%E6%96%B9%E6%A1%88.png",
        itemMsg: `It mainly composes four modules: "vehicle digital display stand", "smart negotiation table", "AR interactive display", and "information display system" to enhance the brand's digital marketing capabilities.`,
      },
    ],
    [
      {
        title: "Data center solution of a Japanese luxury automobile brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E6%97%A5%E7%B3%BB%E8%B1%AA%E5%8D%8E%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%8F%B0%E6%96%B9%E6%A1%88.png",
        itemMsg:
          "the data of each contact is separated, which leads to the data value cannot be maximized and commercialized. Through the support of OneID, combined with the construction of data governance, data modeling and business demand support, the data value can be maximized.",
      },
      {
        title:
          "Mid-office solution for a certain German luxury car brand busines",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/6-%E4%B8%AD%E5%8F%B0%E5%BB%BA%E8%AE%BE/%E6%9F%90%E5%BE%B7%E7%B3%BB%E8%B1%AA%E5%8D%8E%E8%BD%A6%E5%93%81%E7%89%8C%E4%B8%9A%E5%8A%A1%E4%B8%AD%E5%8F%B0%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.png",
        itemMsg:
          "With the development of brand business, the cost of independent construction and maintenance of each business is increasing day by day and the efficiency is getting lower and lower. Through sorting out each business line and extracting, integrating and unifying the common business, it provides effective support for the rapid reference and construction of each business.",
      },
    ],
    [
      {
        title:
          "Intelligent customer service system of a Japanese automobile brand",
        imgUrl:
          "http://cdn.valuetech.com.cn/images/%E6%A1%88%E4%BE%8B%E9%A2%91%E9%81%93/7-%E5%AE%A2%E6%9C%8D%E4%B8%AD%E5%BF%83/%E6%9F%90%E6%97%A5%E7%B3%BB%E6%B1%BD%E8%BD%A6%E5%93%81%E7%89%8C%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.png",
        itemMsg: `In order to better receive customers, the customer service system was upgraded and constructed for the brand, including "robot service, voice recognition, text recognition, key information extraction, labeling, knowledge base construction" and other support.`,
      },
    ],
  ],
};
export default info;
